import React from "react";
import { makeStyles } from "@material-ui/styles";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FunctionsIcon from "@material-ui/icons/Functions";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "0.5rem",
    },
}));

export default function CallToAction(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Button
                component={Link}
                to="/sell"
                size="large"
                variant={props.variant || "outlined"}
                color="primary"
                className={classes.button}
                startIcon={<AttachMoneyIcon />}
            >
                What is my Home Worth?
            </Button>
            <Button
                component={Link}
                to="/listings"
                size="large"
                variant={props.variant || "outlined"}
                color="primary"
                className={classes.button}
                startIcon={<HomeWorkIcon />}
            >
                View Listings
            </Button>
            <Button
                component={Link}
                to="/estimators"
                size="large"
                variant={props.variant || "outlined"}
                color="primary"
                className={classes.button}
                startIcon={<FunctionsIcon />}
            >
                Mortgage Calculator
            </Button>
        </React.Fragment>
    );
}
