import * as React from "react";
import Layout from "../components/layout";
import { makeStyles } from "@material-ui/core/styles/";
import { Grid, Typography } from "@material-ui/core";
import CallToAction from "../components/callToAction";


const useStyles = makeStyles((theme) => ({
    imageContainer: {
        textAlign: "center",
        margin: "1rem 0"
    },
    image: {
        width: "60%",
        margin: "auto",
    },
}));

// markup
const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Grid
                container
                item
                className={classes.imageContainer}
                justifyContent="center"
            >
                <Typography variant="h5">
                    The page you are looking for, could not be found.
                </Typography>
            </Grid>

            <Grid
                container
                item
                className={classes.imageContainer}
                justifyContent="center"
            >
                <Grid item style={{ margin: " 1rem" }}>
                <CallToAction variant="contained"/>
                </Grid>
                <Grid
                    container
                    item
                    className={classes.imageContainer}
                    justifyContent="center"
                >
                    <img
                        src="/404_house.svg"
                        alt="404"
                        className={classes.image}
                    />
                </Grid>
            </Grid>
        </Layout>
    );
};

export default NotFoundPage;
